.MobileNavbar {
  position: absolute;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #1f1913;

  & > .colse-icon {
    position: absolute;
    color: white;
    scale: 1.4;
    top: 1.5rem;
    right: 1.5rem;
  }

  .center {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    justify-content: space-evenly;
    color: #f2ede7;
    font-size: 18px;
    cursor: pointer;

    & > .logo-img {
      width: 150px;
    }

    & > .title {
      text-align: center;
      font-weight: 500;
      .name {
        font-size: 2rem;
      }
    }

    & > .links {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.5rem;

      & > .link-red {
        color: #d70000;
      }
    }

    & > .social-links {
      display: flex;
      gap: 1.3rem;
      margin-top: 2rem;
      color: white;
      scale: 1.3;
    }
  }
}
