.Footer {
  background-color: #18120b;
  padding: 1rem 0;
  text-align: center;

  @media (width > 1000px) {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1.5rem 0;
  }

  & > * {
    margin-top: 0.5rem;
  }

  & > .social-links {
    position: relative;
    display: flex;
    gap: 0.5rem;
    color: #907960;
    justify-content: center;
    align-items: center;
    // margin-bottom: 1rem;

    & > .frame {
      width: 35px;
      height: 35px;
      border: 1px solid #302a24;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        border-color: #907960;
      }

      &:active {
        scale: 0.8;
      }

      & > .social-link {
        width: 15px;
        height: 15px;
      }
    }
  }

  & > .name {
    color: #aa9d8f;
    text-align: center;
  }

  & > .contact {
    color: #aa9d8f;
  }

  & > .freepick {
    color: #aa9d8f;
  }
}
