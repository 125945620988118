.Contact {
  padding-top: 3rem;
  padding-bottom: 2rem;
  // margin-top: 2rem;
  width: 100%;
  background-color: #f7f5f2;

  & > .intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    & > .icon {
      display: block;
      color: #d70000;
      width: 25px;
      height: 25px;
    }

    & > .title {
      color: #261e15;
      font-size: 2.5rem;
      font-weight: 600;
      font-style: italic;
      text-align: center;
    }

    & > .company {
      width: 100%;
      font-size: 1.5rem;
      font-weight: 600;
      text-align: center;
    }

    & > .description {
      font-family: "Roboto Condensed", sans-serif;
      color: #8e8e8e;
      font-size: 1rem;
      text-align: center;
      line-height: 25px;
    }
  }
  & > .card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    padding: 2rem 2rem;

    @media (width < 500px) {
      justify-content: unset;
      margin: auto;
    }

    & > .info {
      padding: 0 1rem;
      display: flex;
      gap: 1rem;
      align-items: center;

      & > .img {
        color: #d70000;
        display: block;

        width: 25px;
        height: 25px;
      }

      .label {
        font-weight: 500;
      }
    }
  }
}
