.Partners {
  padding-top: 86px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  // background-color: #18120b;

  #text {
    max-width: 700px;
    margin: 0 auto;
    margin-top: 3rem;
    padding: 0 1.5rem;
    // background-color: #f7f5f2;
    // border: 1px solid black;
    // border-radius: 1rem;
    // padding-top: 3rem;
  }

  p {
    text-align: justify;
    margin-top: 1rem;
  }

  #boss {
    text-align: right;
    margin-top: 2rem;
  }

  #info {
    margin-top: 2rem;

    div{
      margin-top: 5px;
    }

    padding-bottom: 2rem;
  }

  #line {
    border-top: 1px solid black;
    padding-bottom: 2rem;
  }

  ol {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-bottom: 2rem;

    li {
      padding-top: 5px;

      a {
        cursor: pointer;
        text-decoration: underline;
        font-weight: 600;
      }
    }
  }
}
