.Gifts {
  // padding-top: 4rem;
  // padding-bottom: 2rem;
  padding: 3rem 1rem 2rem 1rem;

  & > .icon {
    display: block;
    color: #d70000;
    margin: auto;
    width: 25px;
    height: 25px;
  }

  & > .title {
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: #261e15;
    font-size: 2.5rem;
    font-weight: 600;
    font-style: italic;
    text-align: center;
  }

  & > .description {
    font-family: "Roboto Condensed", sans-serif;
    // margin: 1rem 2rem;
    color: #8e8e8e;
    font-size: 1.2rem;
    text-align: center;
    line-height: 25px;
    min-height: 90px;
    max-width: 85ch;
    margin: auto;
    padding-bottom: 2rem;
    // border: 1px solid red;
  }

  & > .multi-gifts {
    margin: 0 2rem;
    @media (width > 868px) {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      margin: unset;
    }
  }

  .galery {
    // margin: auto;
    // text-align: center;
    margin-top: 2rem;
    padding-bottom: 1rem;
  }

  .swiper {
    margin-top: -1rem;
    width: 90%;
    height: 200px;

    .swiper-slide {
      display: flex;
      align-items: center;
      overflow: hidden;
      border-radius: 1rem;
    }
  }

  img {
    height: 100%;
    object-fit: cover;
    transition: scale 300ms ease-in-out;

    &:hover {
      // zoom: 2;
      scale: 1.3;
    }
  }
}
