@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;500;600;700&display=swap");

body {
  height: 100vh;
}

#root {
  height: 100%;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 85px;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  font-family: "Barlow Condensed", sans-serif;
}

img {
  width: 100%;
}

a {
  all: unset;
}
