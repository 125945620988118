.Home {
  padding-top: 86px;
  & > .image {
    position: relative;

    & > .title {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: rgba(255, 255, 255, 0.9);
      z-index: 2;
      text-align: center;
      font-weight: 500;
      .name {
        font-weight: 500;
        font-size: 9vw;
      }
      .description {
        font-weight: 500;
        font-size: 5vw;
      }
    }

    & > .overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba($color: #000000, $alpha: 0.7);
    }
  }
}
