.Staff {
  padding: 2rem 0 3rem 0;
  background-color: #19130c;
  color: #f2ede7;

  & > .icon {
    display: block;
    color: #d70000;
    margin: auto;
    width: 25px;
    height: 25px;
  }

  & > .title {
    padding-top: 1rem;
    color: white;
    font-size: 2.5rem;
    font-weight: 600;
    font-style: italic;
    text-align: center;
  }

  & > .description {
    letter-spacing: 0;
    text-align: center;
    line-height: 1.6rem;
    color: #7b7773;
    color: #907960;
    font-style: italic;
    font-weight: 600;
    word-wrap: break-word;
    padding: 1rem 2rem;
  }

  & > .instructor {
    padding-top: 2rem;
    display: flex;
    font-weight: 600;
    color: #907960;
    justify-content: center;

    @media (width < 1000px) {
      & {
        flex-direction: column;
        align-items: center;
        gap: 3rem;
      }
    }

    & > .card {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      gap: 0.8rem;
      max-width: 800px;

      & > .img {
        width: 180px;
        height: 180px;
        border-radius: 50%;
        object-fit: cover;
      }

      & > .name {
        font-weight: 700;
        font-style: italic;
      }

      & > .role {
        color: #d70000;
        font-size: 0.8rem;
        font-weight: 500;
        font-style: italic;
      }

      & > .about {
        letter-spacing: 0;
        text-align: center;
        line-height: 1.6rem;
        color: #7b7773;
        color: #907960;
        font-style: italic;
        word-wrap: break-word;
        padding: 0 2rem;
        text-align: justify;
      }
    }
  }
}
