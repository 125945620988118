.Events {
  background-color: #19130c;
  color: #f2ede7;
  padding: 2rem 0 3rem 0;

  & > .icon {
    display: block;
    color: #d70000;
    margin: auto;

    width: 25px;
    height: 25px;
  }

  & > .title {
    padding-top: 1rem;
    font-size: 2.5rem;
    font-weight: 600;
    font-style: italic;
    text-align: center;
  }

  & > .description {
    letter-spacing: 0;
    text-align: center;
    line-height: 1.6rem;

    font-style: italic;
    word-wrap: break-word;
    padding: 1rem 2rem;
  }

  & > .multi {
    margin: 1rem 2rem;

    .message {
      text-align: center;
    }
  }
}
