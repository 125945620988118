.Benefits {
  width: 100%;
  @media (width > 1000px) {
    & {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  & > .card-light {
    background-color: #f7f5f2;
  }
  & > .card-dark {
    background-color: #edebe8;
  }
  & > .card {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    gap: 0.5rem;
    padding: 2rem;

    &:hover .icon {
      transform: translateY(-2px);
    }

    & > .icon {
      display: block;
      transition: all 0.3s;
      color: #d70000;
      width: 40px;
      height: 40px;
    }
    & > .subtitle {
      color: #7b7773;
      font-weight: 500;
      font-style: italic;
      letter-spacing: 0.4px;
    }
    & > .title {
      color: #261e15;
      font-weight: 600;
      font-style: italic;
      letter-spacing: 0.4px;
      text-align: center;
    }
    & > .description {
      color: #7b7773;
      word-wrap: break-word;
      font-style: italic;
      letter-spacing: 0;
      text-align: justify;
      line-height: 1.6rem;
      max-width: 700px;
    }
  }
}
