.Navbar {
  position: fixed;
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #f4f2ed;
  z-index: 10;

  & > .menu-icon {
    margin-left: auto;
    scale: 1.5;
    cursor: pointer;
  }

  & > .links {
    display: none;
    gap: 2rem;
    margin-left: auto;
    cursor: pointer;
    & > .link-red {
      color: #d70000;
    }

    & > a {
      font-weight: 600;

      &:hover {
        color: #d70000;
      }
    }

    & > .active {
      color: #d70000;
    }
  }

  @media (width > 786px) {
    & > .menu-icon {
      display: none;
    }
    & > .links {
      display: flex;
    }
  }
}
