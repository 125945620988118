.Offers {
  background-color: #18120b;
  padding: 3rem 0;
  text-align: center;

  & > .img {
    color: #d70000;
    display: block;
    margin: auto;
    width: 25px;
    height: 25px;
  }

  & > .offer-title {
    padding-top: 1rem;
    padding-bottom: 3rem;
    font-size: 2.5rem;
    font-weight: 600;
    font-style: italic;
    text-align: center;
    color: white;
  }

  & > .cards {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    margin-bottom: 3rem;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 1rem;
  }

  & > .title {
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    color: #907960;
    margin: auto;
    margin-bottom: 2rem;
    max-width: 90%;
  }

  & > .social-links {
    position: relative;
    display: flex;
    gap: 2.5rem;
    color: #907960;
    justify-content: center;
    align-items: center;

    & > .frame {
      width: 65px;
      height: 65px;
      border: 1px solid #302a24;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        border-color: #907960;
      }

      &:active {
        scale: 0.8;
      }

      & > .social-link {
        width: 35px;
        height: 35px;
      }
    }
  }

  .opacity-low {
    opacity: 0.5;
  }
}
