.Undefined {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f7f5f2;

  .status {
    // font-size: 15vw;
    font-size: clamp(5rem, 15vw, 100rem);
    font-weight: 600;
  }
  .description {
    // font-size: 3vw;
    font-size: clamp(2rem, 3vw, 10rem);
    font-weight: 500;
  }
}
