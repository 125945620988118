.Event {
  max-width: 400px;
  margin: auto;
  border: 1px solid #877d71;
  border-radius: 0.5rem;
  text-align: center;
  padding: 2rem 0;
  margin-bottom: 1rem;
  display: grid;
  gap: 1rem;
  cursor: pointer;

  &:hover {
    border: 1px solid #6d6459;
  }

  &:hover .prop {
    color: #6d6459;
  }

  & > .name {
    font-size: 1.5rem;
    font-weight: 600;
  }
  & > .prop {
    color: #877d71;
    font-weight: 500;
  }

  @media (width > 768px) {
    & {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      align-items: center;
      max-width: unset;
      padding: 1.5rem 0;

      & > .name {
        font-size: 1rem;
        font-weight: 600;
      }
    }
  }
}
